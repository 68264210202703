/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BadRequest,
  BlockedDomain,
  CodeExchange,
  Conflict,
  ConvertTokenResponse,
  Email,
  Forbidden,
  IdentityProvider,
  IntegrationTestTokenResponse,
  MismatchingRedirectUri,
  NotFound,
  PostUsersRequest,
  RefreshRequest,
  SamlSetUpRequest,
  TokenForm,
  TokenResponse,
  Unauthenticated,
  Unauthorized,
  UserClaims,
} from './data-contracts'
import {
  ContentType,
  FullRequestParams,
  HttpClient,
  QueryParamsType,
  RequestParams,
} from '../http-client'

export class V1<SecurityDataType = unknown> {
  // See templates/api.ejs we need this in order to send as parameter to request call
  serviceName = 'auth'
  /**
   * @deprecated Internal use only.
   */
  httpClient: HttpClient<SecurityDataType>

  constructor(config?: ConstructorParameters<typeof HttpClient<SecurityDataType>>[0]) {
    this.httpClient = new HttpClient(config)
  }

  /**
   * @deprecated Internal use only.
   * See src/http-client we need this so we can send service name to http-client so it knows
   * which service is calling it
   */
  request = <T = any, E = any>(params: FullRequestParams): Promise<T> => {
    return this.httpClient.clientRequest<T, E>({ ...params, serviceName: this.serviceName })
  }

  /**
   * @deprecated Internal use only.
   * See src/http-client we need this so we can send service name to http-client so it knows
   * which service is calling it
   */
  getServicePath = <Q extends QueryParamsType>(path: string, query?: Q): string => {
    const servicePath = this.httpClient.clientGetPath({ path, serviceName: this.serviceName })

    if (!query) return servicePath

    const queryParams = this.httpClient.encodeQueryParams(query)
    return `${servicePath}?${queryParams}`
  }

  /**
   * No description
   *
   * @tags Public
   * @name GetAuthV1Login
   * @request GET:/v1/login
   */
  getAuthV1Login = (
    query?: {
      /** @pattern .+@.+ */
      email?: string
      'target-app'?: string
      'product-trial'?: string
      'product-login-theme'?: string
      /** @format int32 */
      port?: number
      crm?: string
      /** @pattern [^\s\\/]+ */
      'preferred-tenant'?: string
      'preferred-cluster'?: string
      /** Temporary hack, will be removed in the foreseeable future. */
      'refresh-token-in-fragment'?: boolean
      'code-exchange'?: string
      'redirect-uri'?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<any, void | Email>({
      path: `/v1/login`,
      method: 'GET',
      query: query,
      ...params,
    })

  /**
   * @description Returns string containing request path for getAuthV1Login */
  getAuthV1LoginPathGetter = (query?: {
    /** @pattern .+@.+ */
    email?: string
    'target-app'?: string
    'product-trial'?: string
    'product-login-theme'?: string
    /** @format int32 */
    port?: number
    crm?: string
    /** @pattern [^\s\\/]+ */
    'preferred-tenant'?: string
    'preferred-cluster'?: string
    /** Temporary hack, will be removed in the foreseeable future. */
    'refresh-token-in-fragment'?: boolean
    'code-exchange'?: string
    'redirect-uri'?: string
  }) => this.getServicePath(`/v1/login`, query)

  /**
   * No description
   *
   * @tags Public
   * @name PostAuthV1Refresh
   * @request POST:/v1/refresh
   * @secure
   */
  postAuthV1Refresh = (data?: RefreshRequest, params: RequestParams = {}) =>
    this.request<TokenResponse, Email | Unauthenticated>({
      path: `/v1/refresh`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for postAuthV1Refresh */
  postAuthV1RefreshPathGetter = () => this.getServicePath(`/v1/refresh`)

  /**
   * No description
   *
   * @tags Public
   * @name PutAuthV1Logout
   * @request PUT:/v1/logout
   */
  putAuthV1Logout = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/logout`,
      method: 'PUT',
      ...params,
    })

  /**
   * @description Returns string containing request path for putAuthV1Logout */
  putAuthV1LogoutPathGetter = () => this.getServicePath(`/v1/logout`)

  /**
   * No description
   *
   * @tags Public
   * @name GetAuthV1WithoutDomain
   * @request GET:/v1/without-domain
   */
  getAuthV1WithoutDomain = (
    query: {
      code: string
      state: string
    },
    params: RequestParams = {}
  ) =>
    this.request<any, void | (CodeExchange | MismatchingRedirectUri) | BlockedDomain>({
      path: `/v1/without-domain`,
      method: 'GET',
      query: query,
      ...params,
    })

  /**
   * @description Returns string containing request path for getAuthV1WithoutDomain */
  getAuthV1WithoutDomainPathGetter = (query: { code: string; state: string }) =>
    this.getServicePath(`/v1/without-domain`, query)

  /**
   * No description
   *
   * @tags Public
   * @name GetAuthV1WithDomain
   * @request GET:/v1/with-domain
   */
  getAuthV1WithDomain = (
    query: {
      code: string
      state: string
    },
    params: RequestParams = {}
  ) =>
    this.request<any, void | (CodeExchange | MismatchingRedirectUri) | BlockedDomain>({
      path: `/v1/with-domain`,
      method: 'GET',
      query: query,
      ...params,
    })

  /**
   * @description Returns string containing request path for getAuthV1WithDomain */
  getAuthV1WithDomainPathGetter = (query: { code: string; state: string }) =>
    this.getServicePath(`/v1/with-domain`, query)

  /**
   * No description
   *
   * @tags Public
   * @name PostAuthV1Token
   * @request POST:/v1/token
   * @secure
   */
  postAuthV1Token = (data: TokenForm, params: RequestParams = {}) =>
    this.request<TokenResponse, Email | Unauthenticated>({
      path: `/v1/token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.UrlEncoded,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for postAuthV1Token */
  postAuthV1TokenPathGetter = () => this.getServicePath(`/v1/token`)

  /**
   * No description
   *
   * @tags Public
   * @name GetAuthV1Users
   * @request GET:/v1/users
   * @secure
   */
  getAuthV1Users = (
    query?: {
      /** @pattern .+@.+ */
      email?: string
      /** @pattern [^\s\\/]+ */
      'user-id-legacy'?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<UserClaims[], BadRequest | Unauthorized | Forbidden>({
      path: `/v1/users`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for getAuthV1Users */
  getAuthV1UsersPathGetter = (query?: {
    /** @pattern .+@.+ */
    email?: string
    /** @pattern [^\s\\/]+ */
    'user-id-legacy'?: string
  }) => this.getServicePath(`/v1/users`, query)

  /**
   * No description
   *
   * @tags Public
   * @name PostAuthV1Users
   * @request POST:/v1/users
   * @secure
   */
  postAuthV1Users = (data: PostUsersRequest, params: RequestParams = {}) =>
    this.request<UserClaims, BadRequest | Unauthorized | Forbidden | Conflict>({
      path: `/v1/users`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for postAuthV1Users */
  postAuthV1UsersPathGetter = () => this.getServicePath(`/v1/users`)

  /**
   * No description
   *
   * @tags Public
   * @name GetAuthV1UsersUserIdLegacy
   * @request GET:/v1/users/{user-id-legacy}
   * @secure
   */
  getAuthV1UsersUserIdLegacy = (userIdLegacy: string, params: RequestParams = {}) =>
    this.request<UserClaims, Email | Unauthorized | Forbidden | NotFound>({
      path: `/v1/users/${userIdLegacy}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for getAuthV1UsersUserIdLegacy */
  getAuthV1UsersUserIdLegacyPathGetter = (userIdLegacy: string) =>
    this.getServicePath(`/v1/users/${userIdLegacy}`)

  /**
   * No description
   *
   * @tags Public
   * @name DeleteAuthV1UsersUserIdLegacy
   * @request DELETE:/v1/users/{user-id-legacy}
   * @secure
   */
  deleteAuthV1UsersUserIdLegacy = (userIdLegacy: string, params: RequestParams = {}) =>
    this.request<void, Email | Unauthorized | Forbidden | NotFound>({
      path: `/v1/users/${userIdLegacy}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })

  /**
   * @description Returns string containing request path for deleteAuthV1UsersUserIdLegacy */
  deleteAuthV1UsersUserIdLegacyPathGetter = (userIdLegacy: string) =>
    this.getServicePath(`/v1/users/${userIdLegacy}`)

  /**
   * No description
   *
   * @tags Public
   * @name GetAuthV1PublicIntegrationTestToken
   * @request GET:/v1/public/integration-test-token
   * @secure
   */
  getAuthV1PublicIntegrationTestToken = (
    query: {
      /** @pattern .+@.+ */
      email: string
      /** @pattern [^\s\\/]+ */
      'tenant-id': string
    },
    params: RequestParams = {}
  ) =>
    this.request<IntegrationTestTokenResponse, Email | Unauthorized | NotFound>({
      path: `/v1/public/integration-test-token`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for getAuthV1PublicIntegrationTestToken */
  getAuthV1PublicIntegrationTestTokenPathGetter = (query: {
    /** @pattern .+@.+ */
    email: string
    /** @pattern [^\s\\/]+ */
    'tenant-id': string
  }) => this.getServicePath(`/v1/public/integration-test-token`, query)

  /**
   * No description
   *
   * @tags Public
   * @name PostAuthV1ConvertTokenLegacyToFire
   * @request POST:/v1/convert-token-legacy-to-fire
   * @secure
   */
  postAuthV1ConvertTokenLegacyToFire = (
    query?: {
      'allow-non-fire-users'?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<ConvertTokenResponse, Email | Unauthorized | NotFound>({
      path: `/v1/convert-token-legacy-to-fire`,
      method: 'POST',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for postAuthV1ConvertTokenLegacyToFire */
  postAuthV1ConvertTokenLegacyToFirePathGetter = (query?: { 'allow-non-fire-users'?: boolean }) =>
    this.getServicePath(`/v1/convert-token-legacy-to-fire`, query)

  /**
   * No description
   *
   * @tags Public
   * @name GetAuthV1IdentityProvidersTenantId
   * @request GET:/v1/identity-providers/{tenant-id}
   * @secure
   */
  getAuthV1IdentityProvidersTenantId = (tenantId: string, params: RequestParams = {}) =>
    this.request<IdentityProvider[], Email | Unauthorized | Forbidden | NotFound>({
      path: `/v1/identity-providers/${tenantId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for getAuthV1IdentityProvidersTenantId */
  getAuthV1IdentityProvidersTenantIdPathGetter = (tenantId: string) =>
    this.getServicePath(`/v1/identity-providers/${tenantId}`)

  /**
   * No description
   *
   * @tags Public
   * @name DeleteAuthV1IdentityProvidersTenantIdIdp
   * @request DELETE:/v1/identity-providers/{tenant-id}/{idp}
   * @secure
   */
  deleteAuthV1IdentityProvidersTenantIdIdp = (
    tenantId: string,
    idp: string,
    params: RequestParams = {}
  ) =>
    this.request<IdentityProvider[], Email | Unauthorized | Forbidden | NotFound>({
      path: `/v1/identity-providers/${tenantId}/${idp}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for deleteAuthV1IdentityProvidersTenantIdIdp */
  deleteAuthV1IdentityProvidersTenantIdIdpPathGetter = (tenantId: string, idp: string) =>
    this.getServicePath(`/v1/identity-providers/${tenantId}/${idp}`)

  /**
   * No description
   *
   * @tags Internal
   * @name PostAuthV1PublicGuestTokens
   * @request POST:/v1/public/guest-tokens
   */
  postAuthV1PublicGuestTokens = (params: RequestParams = {}) =>
    this.request<Email, any>({
      path: `/v1/public/guest-tokens`,
      method: 'POST',
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for postAuthV1PublicGuestTokens */
  postAuthV1PublicGuestTokensPathGetter = () => this.getServicePath(`/v1/public/guest-tokens`)

  /**
   * No description
   *
   * @tags Public
   * @name PostAuthV1SamlSetUp
   * @request POST:/v1/saml-set-up
   * @secure
   */
  postAuthV1SamlSetUp = (data: SamlSetUpRequest, params: RequestParams = {}) =>
    this.request<IdentityProvider, BadRequest | Unauthorized | Forbidden | NotFound>({
      path: `/v1/saml-set-up`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for postAuthV1SamlSetUp */
  postAuthV1SamlSetUpPathGetter = () => this.getServicePath(`/v1/saml-set-up`)

  /**
   * No description
   *
   * @tags Public
   * @name PutAuthV1IdentityProvidersTenantIdIdpExclusive
   * @request PUT:/v1/identity-providers/{tenant-id}/{idp}/exclusive
   * @secure
   */
  putAuthV1IdentityProvidersTenantIdIdpExclusive = (
    tenantId: string,
    idp: string,
    data: boolean,
    params: RequestParams = {}
  ) =>
    this.request<IdentityProvider, Email | Unauthorized | Forbidden | NotFound | Conflict>({
      path: `/v1/identity-providers/${tenantId}/${idp}/exclusive`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })

  /**
   * @description Returns string containing request path for putAuthV1IdentityProvidersTenantIdIdpExclusive */
  putAuthV1IdentityProvidersTenantIdIdpExclusivePathGetter = (tenantId: string, idp: string) =>
    this.getServicePath(`/v1/identity-providers/${tenantId}/${idp}/exclusive`)
}
